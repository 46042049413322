import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Chat } from "@mui/icons-material";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { useDeleteNotification } from "../../store/hooks/useNotifications";

export default function NotificationMenu({ notifications }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const path = useResolvedPath();
  const deleteNotification = useDeleteNotification();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const notificationItems = React.useMemo(() => {
    if (!notifications?.length) {
      return [];
    }
    return (notifications || []).map((notification) => ({
      title: notification?.senderId?.username,
      onClick: () => {
        deleteNotification(notification)
          .then(() => {
            navigate(`/messenger/${notification?.senderId?._id}`);
            handleClose();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    }));
  }, [navigate, notifications, deleteNotification]);
  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Chat />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          disabled={path?.pathname?.startsWith("/messenger")}
          onClick={() => {
            navigate("/messenger");
          }}
        >
          Go to Messenger
        </MenuItem>

        {notificationItems.map(({ title, onClick }, idx) => (
          <MenuItem onClick={onClick} key={idx}>
            You have message(s) from {title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
