import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AuthWrapper from "../layouts/AuthWrapper";
import LoginPage from "../pages/LoginPage";
import ProfilePage from "../pages/ProfilePage";
import MessengerPage from "../pages/MessengerPage";
import RegisterPage from "../pages/RegisterPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ResetPassword";

export default function Router() {
  return (
    <Routes>
      <Route index path="/" element={<HomePage />} />
      <Route path="/profile/:username" element={<ProfilePage />} />
      <Route path="/messenger" element={<MessengerPage />} />
      <Route path="/messenger/:id" element={<MessengerPage />} />
      <Route
        path="/login"
        element={
          <AuthWrapper>
            <LoginPage />
          </AuthWrapper>
        }
      />
      <Route
        path="/register"
        element={
          <AuthWrapper>
            <RegisterPage />
          </AuthWrapper>
        }
      />
      <Route
        path="/resetuserpassword"
        element={
          <AuthWrapper>
            <ResetPasswordPage />
          </AuthWrapper>
        }
      />
      <Route
        path="/forgot"
        element={
          <AuthWrapper>
            <ForgotPasswordPage />
          </AuthWrapper>
        }
      />
    </Routes>
  );
}
