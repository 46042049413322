import "./leftbar.css";
import { Users } from "../../dummyData";
import Online from "../online/Online";
import { useState, useRef } from "react";
import axios from "axios";
import ServPicOne from "../servPicOne/ServPicOne";
import ServPicTwo from "../servPicTwo/ServPicTwo";
import ServPicThree from "../servPicThree/ServPicThree";
import useAuth from "../../store/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toApiUrl, toServerImage } from "../../utils/generic";
import { refreshAccessToken } from "../../apiClient";
import { authenticate } from "../../store/actions/auth";
import { useDispatch } from "react-redux";

const ProfileLeftbar = ({ user }) => {
  const { user: currentUser } = useAuth();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editOrgInfo = useRef();
  const editServices = useRef();
  const [toggle, setToggle] = useState(false);
  const [toggleTwo, setToggleTwo] = useState(false);

  const handleUpdateOrgInfo = async (e) => {
    e.preventDefault();
    try {
      await axios.put(toApiUrl(`/users/${user._id}`), {
        orgInfo: editOrgInfo.current.value,
      });
      await refreshAccessToken(localStorage.getItem("refresh_token"));
      dispatch(authenticate(navigate));
    } catch (err) {}
  };

  const handleUpdateEditServices = async (e) => {
    e.preventDefault();
    try {
      await axios.put(toApiUrl(`/users/${user._id}`), {
        services: editServices.current.value,
      });
      await refreshAccessToken(localStorage.getItem("refresh_token"));
      dispatch(authenticate(navigate));
    } catch (err) {}
  };

  const getUser = () => {
    return user._id === currentUser._id ? currentUser : user;
  };

  return (
    <>
      <div className="post">
        <br />
        <h4 className="leftbarTitle">Information about my Organisation:</h4>
        <div className="rightbarInfo">
          <div className="rightbarInfoItem">
            <span className="rightbarInfoKey">Organisation:</span>
            <span className="rightbarInfoValue">
              <span className="">{getUser().orgInfo}</span>
            </span>
          </div>
          <div></div>
          <div>
            <b>
              {user._id === currentUser._id ? (
                <button
                  onClick={() => setToggle(!toggle)}
                  className="editButton"
                >
                  Edit
                </button>
              ) : null}
            </b>
          </div>
          <br />
          {toggle && (
            <form className="rightbarInfoKey" onSubmit={handleUpdateOrgInfo}>
              Add / Edit Information about your Organisation:
              <br />
              <div className="">
                <textarea
                  defaultValue={getUser()?.orgInfo || ""}
                  placeholder="Organisation Information"
                  ref={editOrgInfo}
                  className="textareass"
                />
              </div>
              <br />
              <button type="submit" className="setButton">
                Save Changes
              </button>
            </form>
          )}
        </div>
      </div>

      <div className="post">
        <br />
        <h4 className="leftbarTitle">
          Some details about my Services / Products:
        </h4>
        <div className="rightbarInfo">
          <div className="rightbarInfoItem">
            <span className="rightbarInfoKey">Services / Products:</span>
            <span className="rightbarInfoValue">
              <span className="">{getUser().services}</span>
            </span>
          </div>
          <div></div>
          <div>
            <b>
              {user._id === currentUser._id ? (
                <button
                  onClick={() => setToggleTwo(!toggleTwo)}
                  className="editButton"
                >
                  Edit
                </button>
              ) : (
                ""
              )}
            </b>
          </div>
          <br />
          {toggleTwo && (
            <form
              className="rightbarInfoKey"
              onSubmit={handleUpdateEditServices}
            >
              Add / Edit Information about your services/products:
              <br />
              <div className="">
                <textarea
                  defaultValue={getUser()?.services || ""}
                  placeholder="Services / Products Information"
                  ref={editServices}
                  className="textareass"
                />
              </div>
              <br />
              <br />
              <button type="submit" className="setButton">
                Save Changes
              </button>
            </form>
          )}
        </div>
      </div>
      <div className="post">
        <br />
        {currentUser._id === user._id ? (
          ""
        ) : (
          <h4 className="leftbarTitle">Pictures of the Products/Services:</h4>
        )}
        {currentUser._id === user._id ? (
          <ServPicOne
            label="Upload First Picture:"
            property="servPic"
            ser={currentUser._id === user._id ? currentUser : user}
          />
        ) : null}
        <img
          className="profileUserImgTwo"
          src={
            currentUser._id === user?._id
              ? toServerImage(currentUser.servPic?.[0])
              : toServerImage(user.servPic?.[0])
          }
          alt=""
        />

        <br />
        {currentUser._id === user._id ? (
          <ServPicOne
            label="Upload Second Picture:"
            property="servPicTwo"
            user={currentUser._id === user._id ? currentUser : user}
          />
        ) : null}
        <br />
        <img
          className="profileUserImgTwo"
          src={
            currentUser._id === user?._id
              ? toServerImage(currentUser.servPicTwo?.[0])
              : toServerImage(user.servPicTwo?.[0])
          }
          alt=""
        />

        <br />
        {currentUser._id === user._id ? (
          <ServPicOne
            label="Upload Third Picture:"
            property="servPicThree"
            user={currentUser._id === user._id ? currentUser : user}
          />
        ) : null}
        <br />
        <img
          className="profileUserImgTwo"
          src={
            currentUser._id === user?._id
              ? toServerImage(currentUser.servPicThree?.[0])
              : toServerImage(user.servPicThree?.[0])
          }
          alt=""
        />
      </div>
    </>
  );
};

const HomeLeftbar = () => {
  return (
    <>
      <div className="birthdayContainer">
        <img className="birthdayImg" src="assets/gift.png" alt="" />
        <span className="birthdayText">
          <b>Pola Foster</b> and <b>3 other friends</b> have a birhday today.
        </span>
      </div>
      <img className="rightbarAd" src="assets/ad.png" alt="" />
      <h4 className="leftbarTitle">Online Friends</h4>
      <ul className="rightbarFriendList">
        {Users.map((u) => (
          <Online key={u.id} user={u} />
        ))}
      </ul>
    </>
  );
};

export default function Leftbar({ user }) {
  return (
    <div className="rightbar">
      <div className="rightbarWrapper">
        {user ? <ProfileLeftbar user={user} /> : <HomeLeftbar />}
      </div>
    </div>
  );
}
