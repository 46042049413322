import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, setUsersLoading } from "../actions/users";
import { useNavigate, useParams } from "react-router-dom";
import ApiClient from "../../apiClient";
import { toApiUrl } from "../../utils/generic";
import useAuth from "./useAuth";

export const useFetchUser = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { username } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  useEffect(() => {
    setLoading(true);
    ApiClient.get(toApiUrl(`/users?username=${username}`))
      .then((res) => {
        setUser(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setUser(null);
        setLoading(false);
        navigate(-1);
      });
  }, [navigate, username]);

  const refetchUser = async () => {
    setLoading(true);
    ApiClient.get(toApiUrl(`/users?username=${username}`))
      .then((res) => {
        setUser(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setUser(null);
        setLoading(false);
        navigate(-1);
      });
  };

  return {
    user,
    setUser,
    loading,
    setLoading,
    isCurrentUser: user && user?._id === currentUser?._id,
    refetchUser,
  };
};

export const useFetchUsers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);
};

export default function useUsers() {
  return useSelector((state) => state.users);
}
