import "./index.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useForm from "../../hooks/useForm";
import ValidationService from "../../services/ValidationService";
import ApiClient from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import Input from "../../components/forms/Input";
import { useState } from "react";
import { toApiUrl } from "../../utils/generic";

export default function ResetPasswordPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formConfig = useForm({
    password: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            min: 6,
            max: 16,
            value,
            noWhiteSpace: true,
          }),
        (value, form) => value === form.passwordAgain.value,
      ],
      onChange: ({ fieldValue, form }) => ({
        password: fieldValue,
        passwordAgain: form.passwordAgain.value,
      }),
    },
    passwordAgain: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            min: 6,
            max: 16,
            value,
            noWhiteSpace: true,
          }),
        (value, form) => {
          return value === form.password.value;
        },
      ],
      onChange: ({ fieldValue, form }) => ({
        passwordAgain: fieldValue,
        password: form.password.value,
      }),
    },
  });

  const handleResetPassword = async (e) => {
    setLoading(true);
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const { password } = formConfig.getFormValues();
      e.preventDefault();
      await ApiClient.post(toApiUrl("/auth/setNewPassword"), {
        password,
        token: searchParams.get("token"),
        userId: searchParams.get("id"),
      });
      addNotification("Password Changed", "success");
      formConfig.resetForm();
      navigate("/login");
    } catch (err) {
      addNotification(
        err?.response?.data?.error || "Password was not changed",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToLogin = async (e) => {
    e.preventDefault();
    navigate("/login");
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const { form, handleChange, isValid, isTouched } = formConfig;

  return (
    <div className="login">
      <div className="loginWrapper">
        <div className="loginLeft">
          <h3 className="loginLogo">PestNu</h3>
          <span className="loginDesc">
            Connect to the PestNu's Digital Platform.
            <br />
            <br />
            <img src={"/assets/logo.jpg"} alt="" width="50%" />
          </span>
        </div>

        <div className="loginRight">
          <div className="title">
            <h3>Set New Password</h3>
          </div>
          <form className="loginBox3" onSubmit={handleResetPassword}>
            <Input
              {...form.password}
              value={form.password.value}
              onChange={handleChange}
              label="Password"
              name="password"
              sx={{ mb: 1 }}
              type="password"
            />
            <Input
              {...form.passwordAgain}
              value={form.passwordAgain.value}
              onChange={handleChange}
              label="Confirm Password"
              name="passwordAgain"
              sx={{ mb: 1 }}
              type="password"
            />

            <button
              className="loginButton"
              type="submit"
              disabled={loading || !isValid || !isTouched}
            >
              {loading ? (
                <CircularProgress color="inherit" size="20px" />
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
          <form className="loginBox2" onSubmit={handleNavigateToLogin}>
            <button className="loginRegisterButton">
              Back to Login / Register
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
