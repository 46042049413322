import "./index.css";

import Conversation from "../../components/conversations/Conversation";
import Message from "../../components/message/Message";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import ConversationName from "../../components/conversationName/ConversationName";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../store/hooks/useAuth";
import ApiClient from "../../apiClient";
import { toApiUrl } from "../../utils/generic";
import withAuth from "../../hoc/withAuth";
import useNotifications, {
  useDeleteNotification,
} from "../../store/hooks/useNotifications";
import { addNotification } from "../../store/actions/notifications";

function MessengerPage({ socket, socketConnected }) {
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { user } = useAuth();
  const scrollRef = useRef();
  const { id } = useParams();
  const { notifications = [] } = useNotifications();
  const deleteNotification = useDeleteNotification();
  useEffect(() => {
    if (socketConnected) {
      socket.on("receive_message", (data) => {
        if (currentChat && data?.conversationId === currentChat._id) {
          setMessages((prev) =>
            [...prev, data].sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        }
      });

      socket.on("new_conversation", (conversation) => {
        setConversations((prev) => [...prev, conversation]);
      });
    }

    return () => {
      if (socket) {
        socket.removeAllListeners("receive_message");
        socket.removeAllListeners("new_conversation");
      }
    };
  }, [socketConnected, socket, currentChat]);

  useEffect(() => {
    socket.emit("addUser", user._id);
    socket.on("getUsers", (users) => {
      // console.log(users);
      setOnlineUsers(
        user.followings.filter((f) => users.some((u) => u.userId === f))
      );
    });
  }, [socket, user]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const res = await ApiClient.get(toApiUrl(`/conversations/${user._id}`));
        setConversations(res.data);

        setCurrentChat(
          res.data.find((c) => c.members.some((member) => member._id === id))
        );
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, [user._id, id]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        if (currentChat?._id) {
          const res = await ApiClient.get(
            toApiUrl(`/messages/${currentChat?._id}`)
          );
          setMessages(res.data);
          // console.log(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
  }, [currentChat?._id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = {
      text: newMessage,
      conversationId: currentChat._id,
    };

    const receiverId = currentChat.members.find(
      (member) => member._id !== user._id
    )?._id;

    try {
      const res = await ApiClient.post(toApiUrl("/messages"), message);
      setMessages((prev) =>
        [...prev, res.data].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
      setNewMessage("");
    } catch (err) {
      console.log(err);
    }

    try {
      await ApiClient.post(toApiUrl("/messages/notification"), {
        userId: receiverId,
      });
    } catch (err) {
      console.log(err);
    }

    try {
      if (!currentChat) {
        return;
      }
      const notificationByCurrentChat = notifications.find((notification) =>
        currentChat.members.some((m) => m._id === notification.senderId?._id)
      );
      if (notificationByCurrentChat) {
        await deleteNotification(notificationByCurrentChat);
      }
    } catch (error) {}
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const navigate = useNavigate();

  return (
    <>
      <div className="messenger">
        <div className="chatMenu">
          <div className="chatMenuWrapper">
            {conversations.map((c, idx) => (
              <div
                key={idx}
                onClick={() => {
                  setCurrentChat(c);
                  const friend = c.members.find(
                    (member) => member._id !== user._id
                  );

                  if (friend) {
                    const notificationByCurrentChat = notifications.find(
                      (notification) =>
                        notification.senderId?._id === friend._id
                    );
                    if (notificationByCurrentChat) {
                      deleteNotification(notificationByCurrentChat);
                    }
                    navigate(`/messenger/${friend._id}`);
                  }
                }}
              >
                <Conversation conversation={c} currentUser={user} />
              </div>
            ))}
          </div>
        </div>
        <div className="chatBox">
          <div className="chatBoxWrapper">
            {currentChat ? (
              <>
                <div></div>
                <div className="currentchat">
                  <ConversationName
                    conversation={currentChat}
                    currentUser={user}
                  />
                </div>
                <div className="chatBoxTop">
                  {messages.map((m) => (
                    <div ref={scrollRef} key={m._id}>
                      <Message
                        message={m}
                        own={m.sender === user._id}
                        profilePicture={
                          currentChat?.members?.find(
                            (member) => member._id === m.sender
                          )?.profilePicture || "person/noAvatar.png"
                        }
                      />
                    </div>
                  ))}
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="chatBoxBottom">
                    <textarea
                      onFocus={async () => {
                        try {
                          const notificationByCurrentChat = notifications.find(
                            (notification) =>
                              currentChat.members.some(
                                (m) => m._id === notification.senderId?._id
                              )
                          );
                          if (notificationByCurrentChat) {
                            await deleteNotification(notificationByCurrentChat);
                          }
                        } catch (error) {}
                      }}
                      className="chatMessageInput"
                      placeholder="write something..."
                      onChange={(e) => setNewMessage(e.target.value)}
                      value={newMessage}
                    ></textarea>
                    <button className="chatSubmitButton" type="submit">
                      Send
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <span className="noConversationText">
                Open a conversation to start a chat.
              </span>
            )}
          </div>
        </div>
        {/* <div className="chatOnline">
          <div className="chatOnlineWrapper">
            <ChatOnline
              onlineUsers={onlineUsers}
              currentId={user._id}
              setCurrentChat={setCurrentChat}
            />
          </div>
        </div> */}
      </div>
    </>
  );
}

export default withAuth(MessengerPage);
