import React from "react";

import CheckboxProton from "../CheckBoxProton/CheckBoxProton";
import "./styles.css";

const FilterPanel = ({
  participations,
  onChange,
  organisation,
  area,
  selectedCountry,
  product,
}) => (
  <div className="filters">
    {/* <div className='input-group'>
      <p className='label'>Category</p>
      <FilterListToggle
        options={categoryList}
        value={selectedCategory}
        selectToggle={selectCategory}
      />
    </div> */}
    <div className="input-group">
      <div>
        <p className="label">Participation Types</p>
        {participations.map((participation) => (
          <CheckboxProton
            key={participation.value}
            input={participation}
            changeChecked={onChange("participations")}
          />
        ))}
      </div>
      <div className="input-group">
        <p className="label">Organisation Types</p>
        {organisation.map((organisation) => (
          <CheckboxProton
            key={organisation.value}
            input={organisation}
            changeChecked={onChange("organisation")}
          />
        ))}
      </div>
      <div className="input-group">
        <p className="label">Area of Activity</p>
        {area.map((area) => (
          <CheckboxProton
            key={area.value}
            input={area}
            changeChecked={onChange("area")}
          />
        ))}
      </div>
    </div>
    <div className="input-group capitalize">
      <p className="label">Country</p>
      {selectedCountry.map((selectedCountry) => (
        <CheckboxProton
          key={selectedCountry.value}
          input={selectedCountry}
          changeChecked={onChange("countries")}
        />
      ))}
    </div>

    <div className="input-group">
      <p className="label">Seeking for</p>
      {product.map((product) => (
        <CheckboxProton
          key={product.value}
          input={product}
          changeChecked={onChange("product")}
        />
      ))}
    </div>

    {/*

    

    <div className='input-group'>
      <p className='label-range'>Price Range</p>
      <SliderProton value={selectedPrice} changePrice={changePrice} />
    </div>
    <div className='input-group'>
      <p className='label'>Star Rating</p>
      <FilterListToggle
        options={ratingList}
        value={selectedRating}
        selectToggle={selectRating}
      />
    </div> */}
  </div>
);

export default FilterPanel;
