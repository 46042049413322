import axios from "axios";
import { useState } from "react";
import "./servPicOne.css";
import { Cancel } from "@mui/icons-material";
import useAuth from "../../store/hooks/useAuth";
import { toApiUrl } from "../../utils/generic";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { refreshAccessToken } from "../../apiClient";
import { authenticate } from "../../store/actions/auth";
import { useRef } from "react";
import { addNotification } from "../../store/actions/notifications";

export default function ServPicOne({ property, label }) {
  const [file, setFile] = useState(null);
  const { user: currentUser } = useAuth();
  const imgRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = async (e) => {
    e.preventDefault();

    if (file) {
      const data = new FormData();
      const fileName = Date.now() + file.name;
      data.append("name", fileName);
      data.append("file", file);

      try {
        await axios.post(toApiUrl("/upload"), data);
        await axios.put(toApiUrl(`/users/${currentUser._id}`), {
          [property]: fileName,
        });
        await refreshAccessToken(localStorage.getItem("refresh_token"));
        dispatch(authenticate(navigate));
        setFile(null);
        imgRef.current.value = null;
        addNotification("Action completed", "success");
      } catch (err) {
        addNotification("Action failed", "error");
      }
    }
  };

  return (
    <div className="profileimage">
      <h4 className="rightbarTitle">{label}</h4>
      {file && (
        <div>
          <img
            src={URL.createObjectURL(file)}
            alt=""
            className="profileUserImgTwo"
          />
          <Cancel
            onClick={() => {
              setFile(null);
              imgRef.current.value = null;
            }}
          />
        </div>
      )}
      <form className="" onSubmit={handleClick}>
        <input
          placeholder="Username"
          type="file"
          id="file"
          ref={imgRef}
          // multiple
          accept=".png,.jpeg,.jpg"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <button type="submit" className="setButton">
          set image
        </button>
      </form>
    </div>
  );
}
