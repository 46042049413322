import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { toServerImage } from "../../../utils/generic";

const maxNameSize = 1000;

const ListItem = ({ item: { username, participation, profilePicture } }) => {
  const getFormattedUserName = () => {
    try {
      const isMoreThanMaxSize =
        !!username &&
        typeof username === "string" &&
        !username.includes(" ") &&
        username?.length > maxNameSize;

      if (!isMoreThanMaxSize) {
        return username;
      }

      return (username || "").split("").slice(0, maxNameSize).join("") + "...";
    } catch (error) {
      // console;
      return username;
    }
  };
  return (
    <div className="listItem-wrap">
      <header>
        <img
          src={toServerImage(profilePicture || "person/noAvatar.png")}
          alt={username}
        />
        <br />
        <h4
          style={{
            maxWidth: "180px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {" "}
          {getFormattedUserName()}
        </h4>
      </header>
      <Link to={`/profile/${username}`}>
        <button type="button" className="button-38">
          User Profile
        </button>
      </Link>

      <footer>
        <span className="capitalize"> {participation}</span>
      </footer>
    </div>
  );
};

export default ListItem;
