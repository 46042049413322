import "./index.css";
import Leftbar from "../../components/leftbar/Leftbar";
import Rightbar from "../../components/rightbar/Rightbar";
import { useFetchUser } from "../../store/hooks/useUsers";
import { toApiUrl, toServerImage } from "../../utils/generic";
import BackdropLoader from "../../components/ui/BackdropLoader";
import withAuth from "../../hoc/withAuth";
import useAuth from "../../store/hooks/useAuth";
import FollowButton from "../../components/follow/FollowButton";
import { useState } from "react";
import ApiClient, { refreshAccessToken } from "../../apiClient";
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { People, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { authenticate } from "../../store/actions/auth";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/actions/notifications";

function ProfilePage({ withFollowing = false }) {
  const { user: currentUser } = useAuth();
  const { user, loading, isCurrentUser, refetchUser, setLoading } =
    useFetchUser();
  const [userNetwork, setUserNetwork] = useState(null);
  const [openAccountSettings, setOpenAccountSettings] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (!user) {
    return null;
  }

  const handleGetNetwork = async (type) => {
    setUserNetwork({
      type,
      loading: true,
    });
    try {
      // console.log(currentUser._id, user._id);
      const { data } = await ApiClient.get(
        toApiUrl(`/users/${type}/${user._id}`)
      );
      setUserNetwork((prev) => ({ ...prev, users: data || [] }));
    } catch (error) {
      setUserNetwork((prev) => ({ ...prev, users: [] }));
    } finally {
      setUserNetwork((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleDismissNetwork = () => setUserNetwork(null);

  const handleAccountOptionsChange = async (payload) => {
    setLoading(true);
    try {
      await ApiClient.put(toApiUrl(`/users/${currentUser._id}`), payload);
      await refreshAccessToken(localStorage.getItem("refresh_token"));
      dispatch(authenticate(navigate));
      refetchUser();
      addNotification("Account Settings Changed", "success");
      // setOpenAccountSettings(false);
    } catch (error) {
      addNotification("Action failed", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BackdropLoader open={loading || userNetwork?.loading} />
      {!!userNetwork && withFollowing ? (
        <Dialog
          open={!!userNetwork}
          onClose={handleDismissNetwork}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle component="h5">
            <People sx={{ mr: 2 }} />{" "}
            {userNetwork.type === "followings" ? "Following" : "Followers"}
          </DialogTitle>
          <DialogContent>
            <List>
              {(userNetwork?.users || []).map((user) => (
                <ListItem key={user._id} sx={{ bgcolor: "#f2f2f2" }}>
                  <ListItemAvatar>
                    <Avatar
                      src={toServerImage(
                        user.profilePicture || "person/noAvatar.png"
                      )}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      user._id === currentUser._id
                        ? `You (${user.username})`
                        : user.username
                    }
                    secondary={user.participation || ""}
                  />
                  <ListItemSecondaryAction>
                    <button
                      type="button"
                      className="button-38"
                      onClick={() => {
                        handleDismissNetwork();
                        navigate(`/profile/${user.username}`);
                      }}
                    >
                      User Profile
                    </button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      ) : null}
      <div className="profile">
        <div className="profileRight">
          <div className="profileRightTop">
            <div className="profileCover">
              <img
                className="profileCoverImg"
                src={toServerImage(
                  (isCurrentUser
                    ? currentUser?.coverPicture
                    : user?.coverPicture) || "/1669030316797background3.png"
                )}
                alt={user?.username || ""}
              />
              <img
                className="profileUserImg"
                src={toServerImage(
                  (isCurrentUser
                    ? currentUser?.profilePicture
                    : user?.profilePicture) || "/1669030316797background3.png"
                )}
                alt={user?.username || ""}
              />
            </div>
            <div className="profileInfo">
              <h4 className="profileInfoName">
                {user.username}
                <Tooltip title="Account Settings">
                  {currentUser?._id === user._id ? (
                    <IconButton onClick={() => setOpenAccountSettings(true)}>
                      <Settings />
                    </IconButton>
                  ) : null}
                </Tooltip>
              </h4>{" "}
              {withFollowing ? (
                <FollowButton user={user} refetchUser={refetchUser} />
              ) : null}
              {withFollowing ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <small
                    className="text-muted"
                    style={{ cursor: "pointer", marginRight: "30px" }}
                    onClick={() =>
                      !user?.followers?.length
                        ? null
                        : handleGetNetwork("followers")
                    }
                  >
                    {user?.followers?.length || 0} Followers
                  </small>
                  <small
                    className="text-muted"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      !user?.followings?.length
                        ? null
                        : handleGetNetwork("followings")
                    }
                  >
                    {user?.followings?.length || 0} Following
                  </small>
                </div>
              ) : null}
              <span className="profileInfoDesc">{user.desc}</span>
            </div>
          </div>
          <div className="profileRightBottom">
            {/* <Feed username={username} />  */}
            <Leftbar user={user} />
            <Rightbar user={user} />
          </div>
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={currentUser?._id === user?._id && openAccountSettings}
        onClose={() => (loading ? null : setOpenAccountSettings(false))}
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          <Settings /> Account Settings
        </DialogTitle>
        <DialogContent>
          <FormControlLabel
            label="Accept mails and promotional material from PestNu project"
            control={
              <Checkbox checked={currentUser?.accountOptions?.acceptMails} />
            }
            onChange={async (_, checked) => {
              await handleAccountOptionsChange({
                accountOptions: {
                  ...currentUser?.accountOptions,
                  acceptMails: checked,
                },
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => setOpenAccountSettings(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withAuth(ProfilePage);
