import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ExitToApp } from "@mui/icons-material";
import "./index.css";
import useAuth from "../../store/hooks/useAuth";
import { toServerImage } from "../../utils/generic";
import { logout } from "../../store/actions/auth";
import { useDispatch } from "react-redux";
import { getNotifications } from "../../store/actions/notifications";
import useNotifications from "../../store/hooks/useNotifications";
import NotificationsMenu from "../NotificationMenu/NotificationMenu";
import { Box } from "@mui/material";

export default function TopBar() {
  const { user, isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  const { notifications = [] } = useNotifications();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    dispatch(getNotifications());
  }, [dispatch, isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="topbarContainer">
      <div className="topbarLeft">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">PestNu</span>
        </Link>
      </div>
      {/* <div className="topbarCenter">
        <div className="searchbar">
          <Search className="searchIcon" />
          <input
            placeholder="Search for friend, post or video"
            className="searchInput"
          />
        </div>
      </div> */}
      <div className="topbarRight">
        <Link to="/" style={{ textDecoration: "none" }}>
          <Box sx={{ display: { sm: "none", xs: "none", md: "revert" } }}>
            <span className="homepageLink">Homepage</span>
          </Box>
        </Link>

        <div className="topbarLinks">
          <Link to={`/profile/${user.username}`}>
            <img
              src={toServerImage(user.profilePicture || "person/noAvatar.png")}
              alt=""
              className="topbarImg"
            />
          </Link>
        </div>
        <div className="topbarIcons">
          <div className="topbarIconItem">
            {/* <Link to={'/messenger'} onClick={render} > */}
            <NotificationsMenu notifications={notifications} />
            <span className="topbarIconBadge">
              {notifications?.length || 0}
            </span>
            {/* </Link> */}
          </div>
        </div>
        <br />
        <span className="topbarLogout">
          <Box sx={{ display: { sm: "none", xs: "none", md: "revert" } }}>
            LogOut
          </Box>

          <ExitToApp onClick={() => dispatch(logout())} />
        </span>
      </div>
    </div>
  );
}
