import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

export const getParticipationList = () => [
  { value: 1, checked: false, label: "Farmer" },
  {
    value: 2,
    checked: false,
    label: "Company expert of the pesticides, fertilisers",
  },
  {
    value: 3,
    checked: false,
    label: "Agriculture, cooperatives, agrofood asociations, clusters",
  },
  { value: 4, checked: false, label: "Precision Agriculture Manufacturing" },
  {
    value: 5,
    checked: false,
    label: "NGO, enviromental, agriculture, agrofood",
  },
  { value: 6, checked: false, label: "University, Research Center" },
  { value: 7, checked: false, label: "Food Grocery & Supermarket Retailers" },
  { value: 8, checked: false, label: "Agronomist" },
  { value: 9, checked: false, label: "Other" },
];

export const getAreas = () => [
  { value: 1, checked: false, label: "Precision Agriculture" },
  { value: 2, checked: false, label: "Robotics" },
  { value: 3, checked: false, label: "Sensors" },
  { value: 4, checked: false, label: "Decision Support Systems" },
  { value: 5, checked: false, label: "Drones" },
  { value: 6, checked: false, label: "Smart Phone Applications" },
  { value: 7, checked: false, label: "Satelites, GNSS" },
  { value: 8, checked: false, label: "Pesticides" },
  { value: 9, checked: false, label: "Fertilisers" },
  { value: 10, checked: false, label: "Other" },
];

export const getOrganizations = () => [
  { value: 1, checked: false, label: "R&D Institution" },
  { value: 2, checked: false, label: "Company/SME" },
  { value: 3, checked: false, label: "Start-up" },
  { value: 4, checked: false, label: "Farm" },
  { value: 5, checked: false, label: "Cluster, DIH, Competence Center" },
  { value: 6, checked: false, label: "Company/Industry" },
  { value: 7, checked: false, label: "Authority/Government" },
  { value: 8, checked: false, label: "Association/Agency" },
  { value: 9, checked: false, label: "Investor" },
  { value: 10, checked: false, label: "Other" },
];

export const getProduct = () => [
  { value: 1, checked: false, label: "Digital Technologies" },
  { value: 2, checked: false, label: "Agroecological Products" },
  { value: 3, checked: false, label: "Organic Farming" },
  { value: 4, checked: false, label: "Other" },
];

export const getCountries = (locale = enLocale) => {
  countries.registerLocale(locale);
  const codes = enLocale.countries;
  return Object.entries(codes).map(([value, label]) => ({
    value,
    label: countries.getName(value, "en"),
    checked: false,
  }));
};
