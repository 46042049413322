import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  root: {
    "&$checked": {
      color: "#000",
    },
  },
  checked: {},
  wrap: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 0,
  },
  label: {
    fontSize: ".8rem",
    fontFamily: `'Raleway', sans-serif`,
  },
});

const CheckboxProton = ({ changeChecked, input }) => {
  const classes = useStyles();
  const { checked, label, value } = input;
  return (
    <div>
      <FormControlLabel
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 0,
        }}
        classes={{
          label: classes.label,
          root: classes.wrap,
        }}
        control={
          <Checkbox
            classes={{
              checked: classes.checked,
              root: classes.root,
            }}
            size="small"
            checked={checked}
            onChange={() => changeChecked(value)}
            inputProps={{ "aria-label": "checkbox with small size" }}
          />
        }
        label={
          <Typography
            sx={{ fontSize: ".8rem", fontFamily: `'Raleway', sans-serif` }}
          >
            {label}
          </Typography>
        }
      />
    </div>
  );
};

export default CheckboxProton;
