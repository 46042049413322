import { Config } from "../config";
import ValidationService from "../services/ValidationService";
import axios from "../apiClient";

export const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(`${Config.STATIC_FILES_UPLOAD_URL}`, formData);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const toServerImage = (src) => {
  try {
    return `${Config.STATIC_FILES_SERVER_URL}${
      src?.startsWith("/") ? src : `/${src}`
    }`;
  } catch (error) {
    return src;
  }
};

export const toApiUrl = (url) => {
  return `${Config.API_BASE_URL}${url}`;
};

export const toPaymentGatewayApiUrl = (url = "") => {
  return `${Config.PAYMENT_GATEWAY_URL}${url}`;
};

export const toRealmsApiUrl = (url = "") => {
  return `${Config.REALMS_BASE_URL}${url}`;
};
export const deriveSafeResponse = (res) => {
  if (
    ValidationService.isObject(res) &&
    !ValidationService.isNullOrUndefinedOrEmpty(res.data)
  ) {
    return res.data;
  }
  return null;
};

export const getPercentage = (upperValue, denominator) => {
  if (denominator === 0) return 0;
  return parseFloat(((upperValue * 100) / denominator).toFixed(2), 10);
};

export const getPercentageString = (upperValue, denominator) => {
  return `${getPercentage(upperValue, denominator)}%`;
};

export const setInputDateString = (
  date,
  formatter = (x) => x?.split("T")?.[0] || ""
) => {
  if (ValidationService.isDate(date)) {
    return formatter(date);
  }
  return "";
};

export const getFileNameSrc = (value) =>
  ValidationService.isString(value) ? value : value?.name;

export const getImageFromFileOrSrc = (value) =>
  ValidationService.isString(value)
    ? toServerImage(value)
    : URL.createObjectURL(value);

export const toArray = (value) => {
  if (Array.isArray(value)) {
    return value;
  }
  if (ValidationService.isNullOrUndefinedOrEmpty(value)) {
    return [];
  }

  return [value];
};

export const getUserFullAddress = (user) => {
  const { address } = user || {};
  return `${address.street}, ${address.city}, ${address.state}`;
};

export const getUserFullName = (user) => {
  return `${user?.name} ${user?.last_name}`;
};

export const getToArrayOrToEmptyArray = (data) =>
  Array.isArray(data) ? data : [];
