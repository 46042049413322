import "./rightbar.css";
import { Users } from "../../dummyData";
import Online from "../online/Online";
import { useState, useMemo } from "react";
import ProfileImage from "../profileImage/profileImage";
import useAuth from "../../store/hooks/useAuth";
import { toApiUrl } from "../../utils/generic";
import ApiClient, { refreshAccessToken } from "../../apiClient";
import { authenticate } from "../../store/actions/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useForm from "../../hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SelectInput from "../forms/SelectInput";
import {
  getAreas,
  getCountries,
  getOrganizations,
  getParticipationList,
  getProduct,
} from "../../data/home";

const ProfileRightbar = ({ user, currentUser }) => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formConfig = useForm({
    participation: {
      defaultValue: user?.participation || "",
      validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
    },
    organisation: {
      defaultValue: user?.organisation || "",
      validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
    },
    selectedCountry: {
      defaultValue: user?.selectedCountry || "",
      validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
    },
    area: {
      defaultValue: user?.area || "",
      validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
    },
    product: {
      defaultValue: user?.product || "",
      validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ApiClient.put(
        toApiUrl(`/users/${user._id}`),
        formConfig.getFormValues()
      );
      await refreshAccessToken(localStorage.getItem("refresh_token"));
      dispatch(authenticate(navigate));
    } catch (err) {}
  };

  const handleCreateConversation = async (e) => {
    e.preventDefault();
    try {
      const { data } = await ApiClient.get(
        toApiUrl(`/conversations/find/${currentUser._id}/${user._id}`)
      );

      if (!data) {
        await ApiClient.post(toApiUrl("/conversations"), {
          senderId: currentUser._id,
          receiverId: user._id,
        });
      }
      navigate(`/messenger/${user._id}`);
    } catch (err) {}
  };

  const participationOptions = useMemo(() => {
    return getParticipationList().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const countriesOptions = useMemo(() => {
    return getCountries().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const organisationOptions = useMemo(() => {
    return getOrganizations().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const areaOptions = useMemo(() => {
    return getAreas().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const productOptions = useMemo(() => {
    return getProduct().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const { form, isValid, isTouched, handleChange } = formConfig;
  const getUser = () => (user._id === currentUser?._id ? currentUser : user);
  return (
    <>
      {/* {user.username !== currentUser.username && (
          <button className="rightbarFollowButton" onClick={handleClick}>
            {followed ? "Unfollow" : "Follow"}
            {followed ? <Remove /> : <Add />}
          </button>
        )} */}
      {user._id !== currentUser._id && (
        <button
          className="rightbarFollowButton"
          onClick={handleCreateConversation}
        >
          Start conversation
        </button>
      )}

      {user.username === currentUser.username && (
        <ProfileImage key={user._id} user={user} />
      )}
      <h4 className="rightbarTitle">User Information</h4>
      <div className="rightbarInfo">
        <div className="rightbarInfoItem">
          <span className="rightbarInfoKey">Participation Type:</span>
          <span className="rightbarInfoValue">
            <span className="capitalize">{getUser().participation}</span>
          </span>
        </div>
        <div></div>
        <div className="rightbarInfoItem">
          <span className="rightbarInfoKey">Country:</span>

          <span className="rightbarInfoValue capitalize">
            {getUser().selectedCountry}
          </span>
        </div>
        <div className="rightbarInfoItem">
          <span className="rightbarInfoKey">Organisation Type:</span>
          <span className="rightbarInfoValue capitalize">
            {getUser().organisation}
          </span>
        </div>
        <div className="rightbarInfoItem">
          <span className="rightbarInfoKey">Area of Activity:</span>
          <span className="rightbarInfoValue capitalize">{getUser().area}</span>
        </div>
        <div className="rightbarInfoItem">
          <span className="rightbarInfoKey">Possible Collaboration on:</span>
          <span className="rightbarInfoValue capitalize">
            {getUser().product}
          </span>
        </div>

        <div>
          <b>
            {user._id === currentUser._id ? (
              <button onClick={() => setToggle(!toggle)} className="editButton">
                Edit my Information
              </button>
            ) : (
              ""
            )}
          </b>
        </div>
        <br />

        {toggle && (
          <form className="rightbarInfoKey" onSubmit={handleSubmit}>
            Edit Participation Type
            <br />
            <SelectInput
              {...form.participation}
              name="participation"
              onChange={handleChange}
              className="editform"
              value={form.participation.value}
              options={participationOptions}
            />
            <br />
            Edit Country
            <div>
              <SelectInput
                {...form.selectedCountry}
                name="selectedCountry"
                value={form.selectedCountry.value}
                fullWidth
                className="loginInput"
                onChange={handleChange}
                options={countriesOptions}
              />
            </div>
            Edit Organisation Type
            <br />
            <SelectInput
              {...form.organisation}
              value={form.organisation.value}
              onChange={handleChange}
              options={organisationOptions}
              name="organisation"
              className="editform"
            />
            <br />
            Edit Area of Activity
            <br />
            <SelectInput
              name="area"
              {...form.area}
              value={form.area.value}
              onChange={handleChange}
              className="editform"
              options={areaOptions}
            />
            <br />
            Edit Provide collaboration on
            <br />
            <SelectInput
              {...form.product}
              value={form.product.value}
              onChange={handleChange}
              options={productOptions}
              name="product"
              className="editform"
            />
            <br />
            <button
              type="submit"
              className="setButton"
              disabled={!isTouched || !isValid}
            >
              Save Changes
            </button>
          </form>
        )}

        {/* <div className="rightbarInfoItem">
            <span className="rightbarInfoKey">Relationship:</span>
            <span className="rightbarInfoValue">
              {user.relationship === 1
                ? "Single"
                : user.relationship === 1
                ? "Married"
                : "-"}
            </span>
          </div> */}
      </div>
      <h4 className="rightbarTitle"></h4>
      <div className="rightbarFollowings">
        {/* {friends.map((friend) => (
            <Link
              to={"/profile/" + friend.username}
              style={{ textDecoration: "none" }}
            >
              <div className="rightbarFollowing">
                <img
                  src={
                    friend.profilePicture
                      ? PF + friend.profilePicture
                      : PF + "person/noAvatar.png"
                  }
                  alt=""
                  className="rightbarFollowingImg"
                />
                <span className="rightbarFollowingName">{friend.username}</span>
              </div>
            </Link>
          ))} */}
      </div>
    </>
  );
};

const HomeRightbar = () => {
  return (
    <>
      <div className="birthdayContainer">
        <img className="birthdayImg" src="assets/gift.png" alt="" />
        <span className="birthdayText">
          <b>Pola Foster</b> and <b>3 other friends</b> have a birhday today.
        </span>
      </div>
      <img className="rightbarAd" src="assets/ad.png" alt="" />
      <h4 className="rightbarTitle">Online Friends</h4>
      <ul className="rightbarFriendList">
        {Users.map((u) => (
          <Online key={u.id} user={u} />
        ))}
      </ul>
    </>
  );
};

export default function Rightbar({ user }) {
  const { user: currentUser } = useAuth();

  return (
    <div className="rightbar">
      <div className="rightbarWrapper">
        {user ? (
          <ProfileRightbar
            key={user?._id}
            user={user}
            currentUser={currentUser}
          />
        ) : (
          <HomeRightbar />
        )}
      </div>
    </div>
  );
}
