import useNotifications from "../../store/hooks/useNotifications";
import { toServerImage } from "../../utils/generic";
import "./conversation.css";

export default function Conversation({ conversation, currentUser }) {
  const { notifications = [] } = useNotifications();

  const user = (conversation?.members || []).find(
    (user) => user._id !== currentUser._id
  );

  if (!user) {
    return null;
  }

  const hasNewMessage = (notifications || []).find(
    (notification) => notification.senderId._id === user._id
  );
  return (
    <div className="conversation">
      <img
        className="conversationImg"
        src={toServerImage(user?.profilePicture || "person/noAvatar.png")}
        alt=""
      />
      <span className="conversationName">{user?.username}</span>
      {hasNewMessage ? <span className="newMessageBadge">1</span> : null}
    </div>
  );
}
