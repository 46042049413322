import "./index.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useForm from "../../hooks/useForm";
import ValidationService from "../../services/ValidationService";
import ApiClient from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import Input from "../../components/forms/Input";
import { useState } from "react";
import { toApiUrl } from "../../utils/generic";

export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formConfig = useForm({
    email: {
      defaultValue: "",
      validators: ValidationService.validateEmail,
    },
  });

  const handleResetPassword = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      await ApiClient.post(
        toApiUrl("/auth/resetPassword"),
        formConfig.getFormValues()
      );
      addNotification(
        "Password Reset request has been sent. Please check your inbox",
        "success"
      );
      formConfig.resetForm();
    } catch (err) {
      addNotification(
        "Password Reset request was not sent. Please try again later",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToLogin = async (e) => {
    e.preventDefault();
    navigate("/login");
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const { form, handleChange, isValid, isTouched } = formConfig;

  return (
    <div className="login">
      <div className="loginWrapper">
        <div className="loginLeft">
          <h3 className="loginLogo">PestNu</h3>
          <span className="loginDesc">
            Connect to the PestNu's Digital Platform.
            <br />
            <br />
            <img src={"assets/logo.jpg"} alt="" width="50%" />
          </span>
        </div>

        <div className="loginRight">
          <div className="title">
            <h3>Reset</h3>
          </div>
          <form className="loginBox3" onSubmit={handleResetPassword}>
            <Input
              {...form.email}
              value={form.email.value}
              onChange={handleChange}
              label="Email"
              type="email"
            />

            <button
              className="loginButton"
              type="submit"
              disabled={loading || !isValid || !isTouched}
            >
              {loading ? (
                <CircularProgress color="inherit" size="20px" />
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
          <form className="loginBox2" onSubmit={handleNavigateToLogin}>
            <button className="loginRegisterButton">
              Back to Login / Register
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
