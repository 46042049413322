import ApiClient from "../../apiClient";
import { toApiUrl } from "../../utils/generic";
import { NotificationActions } from "../reducers/notificationReducer";
import { toast } from "react-toastify";

export const addNotification = (message, type = "info") => {
  switch (type) {
    case "success":
      toast.success(message);
      break;
    case "info":
      toast.info(message);
      break;
    case "error":
      toast.error(message);
      break;
    case "warning":
      toast.warning(message);
      break;
    default:
      toast.info(message);
  }
};

export const getNotifications = () => async (dispatch) => {
  try {
    const { data } = await ApiClient.get(toApiUrl("/messages/notifications"));
    if (Array.isArray(data)) {
      dispatch({ type: NotificationActions.GetNotifications, payload: data });
    }
  } catch (error) {}
};
