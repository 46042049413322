import React, { useCallback, useMemo, useState } from "react";
import "./index.css";
import useUsers, { useFetchUsers } from "../../store/hooks/useUsers";
import withBackdropLoader from "../../hoc/withBackdropLoader";
import { connect } from "react-redux";
import FilterPanel from "../../components/filterPanel/FilterPanel";
import {
  getAreas,
  getCountries,
  getOrganizations,
  getParticipationList,
  getProduct,
} from "../../data/home";
import List from "../../components/List/List";
import withAuth from "../../hoc/withAuth";

function HomePage() {
  useFetchUsers();
  const { users } = useUsers();

  const [participations, setParticipations] = useState(() =>
    getParticipationList()
  );
  const [area, setArea] = useState(() => getAreas());
  const [organisation, setOrganisation] = useState(() => getOrganizations());
  const [product, setProduct] = useState(() => getProduct());
  const [countries, setCountries] = useState(() => getCountries());

  const stateEntrySetterByName = (name) => {
    switch (name) {
      case "participations":
        return setParticipations;
      case "area":
        return setArea;
      case "organisation":
        return setOrganisation;
      case "product":
        return setProduct;
      case "countries":
        return setCountries;
      default:
        return null;
    }
  };

  const handleChange = (name) => (value) => {
    const setter = stateEntrySetterByName(name);
    if (!setter) {
      return;
    }

    setter((prev) =>
      prev.map((item) =>
        item.value === value ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const getCheckedEntities = (array) =>
    (array || []).filter((entity) => !!entity.checked);

  const applyFilters = useCallback(
    (users) => {
      const participationsCheckedOptions = getCheckedEntities(participations);
      const countriesCheckedOptions = getCheckedEntities(countries);
      const areaCheckedOptions = getCheckedEntities(area);
      const organisationCheckedOptions = getCheckedEntities(organisation);
      const productCheckedOptions = getCheckedEntities(product);

      return users.filter((user) => {
        return (
          (!participationsCheckedOptions.length ||
            participationsCheckedOptions.some(
              (item) => item.label.toLowerCase() === user.participation
            )) &&
          (!countriesCheckedOptions.length ||
            countriesCheckedOptions.some(
              (c) => c.label.toLowerCase() === user.selectedCountry
            )) &&
          (!areaCheckedOptions.length ||
            areaCheckedOptions.some(
              (c) => c.label.toLowerCase() === user.area
            )) &&
          (!organisationCheckedOptions.length ||
            organisationCheckedOptions.some(
              (c) => c.label.toLowerCase() === user.organisation
            )) &&
          (!productCheckedOptions.length ||
            productCheckedOptions.some(
              (c) => c.label.toLowerCase() === user.product
            ))
        );
      });
    },
    [area, countries, organisation, participations, product]
  );

  const listItems = useMemo(() => {
    return applyFilters(users);
  }, [users, applyFilters]);

  const countriesOptions = useMemo(() => {
    if (!Array.isArray(users) || !countries?.length) {
      return [];
    }
    return countries.filter((c) =>
      (users || []).some((u) => u.selectedCountry === c.label.toLowerCase())
    );
  }, [countries, users]);

  return (
    <div className="homeContainer">
      <div className="home">
        <div className="home_panelList-wrap">
          <div className="home_panel-wrap">
            <FilterPanel
              participations={participations}
              selectedCountry={countriesOptions}
              organisation={organisation}
              area={area}
              product={product}
              onChange={handleChange}
            />
          </div>
          <div className="home_list-wrap">
            <List list={listItems} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ users }) => ({ loading: users.loading });

export default withAuth(connect(mapStateToProps)(withBackdropLoader(HomePage)));
