import ApiClient from "../../apiClient";
import { deriveSafeResponse, toApiUrl } from "../../utils/generic";

const { UsersActions } = require("../reducers/userReducer");

export const setUsersLoading = (loading) => (dispatch) =>
  dispatch({ type: UsersActions.Loading, payload: loading });

export const getAllUsers =
  (page = 1, view = 10, filters = {}) =>
  async (dispatch) => {
    dispatch(setUsersLoading(true));
    let res;
    try {
      res = await ApiClient.get(toApiUrl("/users/usersall"), {
        params: { page, view, ...filters },
      });
      dispatch({ type: UsersActions.GetUsers, payload: res.data });
    } catch (error) {
    } finally {
      dispatch(setUsersLoading(false));
      return deriveSafeResponse(res);
    }
  };
