import { useDispatch, useSelector } from "react-redux";
import { NotificationActions } from "../reducers/notificationReducer";
import { useCallback } from "react";
import ApiClient from "../../apiClient";
import { toApiUrl } from "../../utils/generic";

export const useSetNotification = () => {
  const dispatch = useDispatch();

  const setNotification = useCallback(
    (notification) =>
      dispatch({
        type: NotificationActions.SetNotification,
        payload: notification,
      }),
    [dispatch]
  );
  return setNotification;
};

export const useDeleteNotification = () => {
  const dispatch = useDispatch();

  const deleteNotification = useCallback(
    async (notification) => {
      try {
        await ApiClient.delete(
          toApiUrl(`/messages/notifications/${notification._id}`)
        );
        dispatch({
          type: NotificationActions.DeleteNotification,
          payload: notification?._id,
        });
      } catch (error) {}
    },
    [dispatch]
  );
  return deleteNotification;
};

export default function useNotifications() {
  return useSelector((state) => state.userNotifications);
}
