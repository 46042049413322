export const Config = {
  WS_URL: process.env.NODE_ENV === "development" ? "ws://localhost:8800/" : "/",
  API_BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8800/api"
      : "/api",
  STATIC_FILES_UPLOAD_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8800/api/upload"
      : "/api/upload",
  STATIC_FILES_SERVER_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8800/images"
      : "/images",
};
