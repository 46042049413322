const initialState = {
  notifications: [],
};

export const NotificationActions = {
  GetNotifications: "GetNotifications",
  SetNotification: "SetNotification",
  DeleteNotification: "DeleteNotification",
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NotificationActions.GetNotifications:
      return {
        ...state,
        notifications: action.payload,
      };
    case NotificationActions.SetNotification: {
      const notifications = Array.isArray(state.notifications)
        ? state.notifications
        : [];
      return {
        ...state,
        notifications: [action.payload, ...notifications],
      };
    }
    case NotificationActions.DeleteNotification: {
      const notifications = Array.isArray(state.notifications)
        ? state.notifications
        : [];
      return {
        ...state,
        notifications: notifications.filter(
          (not) => not._id !== action.payload
        ),
      };
    }
    default:
      return state;
  }
};
