import "./conversationName.css";

export default function ConversationName({ conversation, currentUser }) {
  const user = (conversation?.members || []).find(
    (user) => user._id !== currentUser._id
  );

  return (
    <div className="">
      <span className="conversationName">{user?.username}</span>
      {/* {console.log(`edwww ${user?.username}`)} */}
    </div>
  );
}
