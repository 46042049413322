import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { authenticate, logout } from "../store/actions/auth";
import useAuth from "../store/hooks/useAuth";
import BackdropLoader from "../components/ui/BackdropLoader";
import { refreshAccessToken } from "../apiClient";
import { socket } from "../sockets";
import { NotificationTypes } from "../data/notifications";
import { useSetNotification } from "../store/hooks/useNotifications";
import { addNotification } from "../store/actions/notifications";
import { Dialog, DialogTitle } from "@mui/material";

function withAuth(Component, type = "HARD") {
  const WrappedComponent = Component;
  return (props) => {
    const { user, isAuthenticated, loading } = useAuth();
    const [socketConnected, setSocketConnected] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    const setNotification = useSetNotification();
    useEffect(() => {
      if (isAuthenticated) {
        return;
      }
      const token = localStorage.getItem("access_token");
      switch (type) {
        case "HARD": {
          if (!token) {
            dispatch(logout());
            navigate("/login");
            return;
          }
          dispatch(authenticate(navigate));
          break;
        }
        case "SOFT": {
          if (token) {
            dispatch(authenticate(navigate));
          }
          break;
        }
        default:
          dispatch(logout());
          navigate("/login");
          return;
      }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
      const interval = setInterval(() => {
        const refresh_token = localStorage.getItem("refresh_token");
        if (!refresh_token) {
          return;
        }
        refreshAccessToken(refresh_token)
          .then((access_token) => {
            socket.auth.token = access_token;
            socket.disconnect().connect();
          })
          .catch((err) => {
            logout();
          });
      }, 5 * 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }, []);

    useEffect(() => {
      if (!isAuthenticated) {
        return;
      }
      socket.auth.token = localStorage.getItem("access_token");
      socket.connect();

      setSocketConnected((prev) => true);
      socket.on(NotificationTypes.new_message, (notification) => {
        setNotification(notification);
      });
      socket.on(NotificationTypes.new_follower, (follower) => {
        addNotification(`New follower ${follower?.username}`, "info");
      });
      socket.io.on("reconnect", () => {
        setSocketConnected((prev) => true);
        socket.emit("join", user?.id);
      });
      socket.io.on("reconnect_error", (err) => {
        setSocketConnected((prev) => false);
      });
      socket.io.on("error", (err) => {
        console.log(err);
      });

      return () => {
        setSocketConnected((prev) => false);
        socket.removeAllListeners();
        socket.disconnect();
      };
    }, [isAuthenticated, user, dispatch, setNotification]);

    if (loading) {
      return <BackdropLoader open={loading} />;
    }

    if (type === "HARD" && !token) {
      return null;
    }

    if (!isAuthenticated && type !== "SOFT") {
      return <BackdropLoader open={true} />;
    }
    return (
      <>
        <WrappedComponent
          {...props}
          user={user}
          socket={socket}
          socketConnected={socketConnected}
        />
      </>
    );
  };
}

export default withAuth;
