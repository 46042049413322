import "./index.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useForm from "../../hooks/useForm";
import ValidationService from "../../services/ValidationService";
import Input from "../../components/forms/Input";
import useAuth from "../../store/hooks/useAuth";
import { login } from "../../store/actions/auth";
import { connect } from "react-redux";
import { addNotification } from "../../store/actions/notifications";

function LoginPage({ login }) {
  const { loading } = useAuth();
  const formConfig = useForm({
    email: {
      defaultValue: "",
      validators: ValidationService.validateEmail,
    },
    password: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({
          min: 6,
          max: 16,
          value,
          noWhiteSpace: true,
        }),
    },
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await login(formConfig.getFormValues());
    if (res) {
      addNotification(`Welcome ${res?.username}`, "success");
      navigate("/");
      formConfig.resetForm();
      return;
    }
    addNotification(`Login failed`, "error");
  };

  const goToRegister = async (e) => {
    e.preventDefault();

    navigate("/register");
  };
  const gotToForgotPassword = async (e) => {
    e.preventDefault();

    navigate("/forgot");
  };

  const { form, handleChange, isValid, isTouched } = formConfig;

  return (
    <div className="login">
      <div className="loginWrapper">
        <div className="loginLeft">
          <h3 className="loginLogo">PestNu</h3>
          <span className="loginDesc">
            Connect to the PestNu's Digital Platform.
            <br />
            <br />
            <img src={"/assets/logo.jpg"} alt="" width="50%" />
          </span>
        </div>

        <div className="loginRight">
          <div className="title">
            <h3>LOGIN</h3>
          </div>
          <form className="loginBox3" onSubmit={handleSubmit}>
            <Input
              sx={{ my: 2 }}
              {...form.email}
              placeholder="Email"
              type="email"
              required
              value={form.email.value}
              onChange={handleChange}
            />
            <Input
              sx={{ my: 2 }}
              {...form.password}
              placeholder="Password"
              type="password"
              required
              minLength="6"
              value={form.password.value}
              onChange={handleChange}
            />

            <button
              className="loginButton"
              type="submit"
              disabled={!isValid || !isTouched || loading}
            >
              {loading ? (
                <CircularProgress color="inherit" size="20px" />
              ) : (
                "Log In"
              )}
            </button>
            <button onClick={gotToForgotPassword} className="loginButton">
              <span className="">Forgot Password?</span>
            </button>
          </form>
          <form className="loginBox2" onSubmit={goToRegister}>
            <button className="loginRegisterButton">New Account</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { login })(LoginPage);
