import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import { userReducer } from "./userReducer";
import { notificationsReducer } from "./notificationReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  notifications: toastrReducer,
  users: userReducer,
  userNotifications: notificationsReducer,
});
