import { useMemo } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import useForm from "../../hooks/useForm";
import ValidationService from "../../services/ValidationService";
import {
  getAreas,
  getCountries,
  getOrganizations,
  getParticipationList,
  getProduct,
} from "../../data/home";
import Input from "../../components/forms/Input";
import SelectInput from "../../components/forms/SelectInput";
import ApiClient from "../../apiClient";
import { toApiUrl } from "../../utils/generic";
import { addNotification } from "../../store/actions/notifications";
import { useState } from "react";
import BackdropLoader from "../../components/ui/BackdropLoader";
import {
  Dialog,
  DialogContent,
  Alert,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

export default function RegisterPage() {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formConfig = useForm({
    username: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 2 }),
    },
    email: {
      defaultValue: "",
      validators: ValidationService.validateEmail,
    },
    password: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            min: 6,
            max: 16,
            value,
            noWhiteSpace: true,
          }),
        (value, form) => value === form.passwordAgain.value,
      ],
      onChange: ({ fieldValue, form }) => ({
        password: fieldValue,
        passwordAgain: form.passwordAgain.value,
      }),
    },
    passwordAgain: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            min: 6,
            max: 16,
            value,
            noWhiteSpace: true,
          }),
        (value, form) => {
          return value === form.password.value;
        },
      ],
      onChange: ({ fieldValue, form }) => ({
        passwordAgain: fieldValue,
        password: form.password.value,
      }),
    },
    organisation: {
      defaultValue: "",
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
      validators: (value) =>
        ValidationService.isOneOf({
          value,
          options: getOrganizations().map((x) => x.label.toLowerCase()),
        }),
    },
    participation: {
      defaultValue: "",
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
      validators: (value) =>
        ValidationService.isOneOf({
          value,
          options: getParticipationList().map((x) => x.label.toLowerCase()),
        }),
    },
    area: {
      defaultValue: "",
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
      validators: (value) =>
        ValidationService.isOneOf({
          value,
          options: getAreas().map((x) => x.label.toLowerCase()),
        }),
    },
    product: {
      defaultValue: "",
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
      validators: (value) =>
        ValidationService.isOneOf({
          value,
          options: getProduct().map((x) => x.label.toLowerCase()),
        }),
    },
    selectedCountry: {
      defaultValue: "",
      formatter: (value) =>
        ValidationService.isString(value) ? value.toLowerCase() : value,
      validators: (value) =>
        ValidationService.isOneOf({
          value,
          options: getCountries().map((x) => x.label.toLowerCase()),
        }),
    },
    acceptMails: {
      defaultValue: true,
    },
  });

  const { form, handleChange, isValid, isTouched, handleBaseChange } =
    formConfig;

  const navigate = useNavigate();

  const participationOptions = useMemo(() => {
    return getParticipationList().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const countriesOptions = useMemo(() => {
    return getCountries().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const organisationOptions = useMemo(() => {
    return getOrganizations().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const areaOptions = useMemo(() => {
    return getAreas().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const productOptions = useMemo(() => {
    return getProduct().map((x) => ({
      label: x.label,
      value: x.label.toLowerCase(),
    }));
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    const { passwordAgain, ...formValues } = formConfig.getFormValues();
    setLoading(true);
    try {
      await ApiClient.post(toApiUrl(`/auth/register`), formValues);
      setShowModal(true);
      formConfig.resetForm();
    } catch (err) {
      console.log(err);
      addNotification("Registration failed", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToLogin = async (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const onCheckEmailAlreadyExists = async () => {
    try {
      if (!form.email.isValid) {
        return;
      }
      setLoading(true);

      await ApiClient.post(toApiUrl("/auth/check-email"), {
        email: form.email.value,
      });
      setLoading(false);
    } catch (error) {
      addNotification(error?.response?.data?.error, "error");
      handleBaseChange("email", "");

      setLoading(false);
    }
  };

  return (
    <div className="login">
      <BackdropLoader open={loading} />
      <div className="registerWrapper">
        <div className="loginLeft">
          <h3 className="loginLogo">PestNu</h3>

          <span className="loginDesc">
            Connect with PestNu's stakeholders all around the world.
            <br />
            <br />
            <img src={"assets/logo.jpg"} alt="" width="50%" />
          </span>
        </div>
        <div className="loginRight">
          <div className="title">
            <h3>REGISTER</h3>
          </div>
          <form className="loginBox" onSubmit={handleRegister}>
            <Input
              {...form.username}
              value={form.username.value}
              onChange={handleChange}
              label="Username"
              name="username"
              sx={{ mb: 1 }}
            />
            <Input
              {...form.email}
              value={form.email.value}
              onChange={handleChange}
              onBlur={onCheckEmailAlreadyExists}
              label="Email"
              name="email"
              sx={{ mb: 1 }}
            />
            <Input
              {...form.password}
              value={form.password.value}
              onChange={handleChange}
              label="Password"
              name="password"
              sx={{ mb: 1 }}
              type="password"
            />
            <Input
              {...form.passwordAgain}
              value={form.passwordAgain.value}
              onChange={handleChange}
              label="Confirm Password"
              name="passwordAgain"
              sx={{ mb: 1 }}
              type="password"
            />
            <SelectInput
              {...form.participation}
              value={form.participation.value}
              label="Participation Type"
              name="participation"
              options={participationOptions}
              onChange={handleChange}
              sx={{ mb: 1 }}
            />
            <SelectInput
              {...form.selectedCountry}
              value={form.selectedCountry.value}
              label="Country"
              name="selectedCountry"
              options={countriesOptions}
              onChange={handleChange}
              sx={{ mb: 1 }}
            />
            <SelectInput
              {...form.organisation}
              value={form.organisation.value}
              label="Participation Type"
              name="organisation"
              options={organisationOptions}
              onChange={handleChange}
              sx={{ mb: 1 }}
            />
            <SelectInput
              {...form.area}
              value={form.area.value}
              label="Area"
              name="area"
              options={areaOptions}
              onChange={handleChange}
              sx={{ mb: 1 }}
            />
            <SelectInput
              {...form.product}
              value={form.product.value}
              label="Provide collaboration on"
              name="product"
              options={productOptions}
              onChange={handleChange}
              sx={{ mb: 1 }}
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.acceptMails.value}
                    name="acceptMails"
                    onChange={handleChange}
                  />
                }
                label="I agree to accept mails and promotional material from PestNu project"
              />
            </div>

            <button
              className="loginButton"
              type="submit"
              disabled={!isValid || !isTouched}
            >
              Sign Up
            </button>
          </form>
          <form className="loginBox2" onSubmit={handleNavigateToLogin}>
            <button className="loginRegisterButton">Log into Account</button>
          </form>
        </div>
      </div>
      <Dialog
        open={showModal}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setShowModal(false);
        }}
      >
        <DialogContent sx={{ minHeight: "80px", p: 2 }}>
          <Alert severity="success">Registration completed successfully</Alert>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => navigate("/login")}
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
