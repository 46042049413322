import React, { useState } from "react";
import "./index.css";
import ApiClient, { refreshAccessToken } from "../../apiClient";
import useAuth from "../../store/hooks/useAuth";
import { toApiUrl } from "../../utils/generic";
import { Button, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { authenticate } from "../../store/actions/auth";
import { useNavigate } from "react-router-dom";
import { Bookmark, BookmarkRemove } from "@mui/icons-material";

export default function FollowButton({ user, refetchUser }) {
  const [loading, setLoading] = useState(false);
  const { user: currentUser } = useAuth();
  const isCurrentUser = user._id === currentUser?._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (isCurrentUser) {
    return null;
  }

  const isFollowing = currentUser?.followings?.some((u) => u === user?._id);

  const handleFollow = async () => {
    setLoading(true);
    try {
      await ApiClient.put(
        toApiUrl(`/users/${user._id}/${isFollowing ? "unfollow" : "follow"}`)
      );
      await refreshAccessToken(localStorage.getItem("refresh_token"));
      dispatch(authenticate(navigate));
      refetchUser();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={isFollowing ? <BookmarkRemove /> : <Bookmark />}
      onClick={handleFollow}
      className="button-38"
      disabled={loading}
    >
      {loading ? <CircularProgress color="inherit" size="sm" /> : null}
      {isFollowing ? "Unfollow" : "Follow"}
    </Button>
  );
}
