import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

const withBackdropLoader = (Component) => (props) => {
  return (
    <>
      {props.loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Component {...props} />
    </>
  );
};

export default withBackdropLoader;
